import { globalTheme } from 'srs.sharedcomponents/lib/esm/styles/config'

const themeColor = '#002469'
const blueColor = 'rgb(14, 22, 100) !important'
export const customTheme = {
  ...globalTheme,
  colors: {
    ...globalTheme.colors,
    primary: themeColor,
    leftMenuItem: blueColor,
    sideBarActiveText: blueColor,
    menuButton: themeColor,
  },
}
