import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";

const routesConfig = {
  authenticationRoutes: {
    ...routerConfig.authenticationRoutes,
    "/login": {
      path: "/login",
      component: "ChooseLogin",
      pageTitle: "Login",
    },
    "/retail-login": {
      path: "/retail-login",
      component: "LoginPage",
      pageTitle: "txtLogin",
    },
  },
  publicRoutes: {
    "/service-support": {
      path: "/service-support",
      component: "ServiceSupport",
      pageTitle: "Service Support",
    },
    "/ev-servicing": {
      path: "/ev-servicing",
      component: "BuilderUIComponent",
      pageTitle: "Ev Servicing",
      modelKey: "ev-servicing",
      modelName: "content-page",
    },
    "/browser-suppliers": {
      path: "/browser-suppliers",
      component: "BrowserSuppliers",
      pageTitle: "Browser Suppliers",
    },
  },
};

export default routesConfig;
