import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from "srs.sharedcomponents/lib/esm/components/Spinner";
import * as S from './style';
import { env } from 'srs.sharedcomponents/lib/esm/api/env';

// Define the interfaces
interface SupplierList {
  [key: string]: string[];
}

const SupplierListComponent: React.FC = () => {
  const [suppliers, setSuppliers] = useState<SupplierList>({});

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get<SupplierList>(`${env.REACT_APP_SERVER_URL}/Catalog/GetSupplierList`);
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching supplier list:', error);
      }
    };

    fetchSuppliers();
  }, []);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  if (!suppliers || Object.keys(suppliers).length === 0) {
    return (
      <S.Container style={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spinner />
      </S.Container>
    )
  }

  return (
    <S.Container>
      <S.Breadcrumbs data-cy="breadcrumbs">
        <S.BreadcrumbLink href="#">Home</S.BreadcrumbLink> {'>'}  <S.BreadcrumbSpan>Supplier List</S.BreadcrumbSpan>
      </S.Breadcrumbs>
      <S.Title>Browse Suppliers</S.Title>
      <S.Alphabet data-cy="alphabet">
        {alphabet.map(letter => (
          <S.AlphabetLink key={letter} href={`#${letter}`} data-cy={`alphabet-link-${letter}`}>
            {letter}
          </S.AlphabetLink>
        ))}
      </S.Alphabet>
      <S.SupplierListContainer data-cy="supplier-list">
        {alphabet.map(letter => (
          <>
            <h2>{letter}</h2>
            <S.SupplierColumn key={letter} id={letter} data-cy={`supplier-column-${letter}`}>
            {suppliers[letter] ? (
              suppliers[letter].map(supplier => (
                <S.SupplierLink key={supplier} href={`/ProductListCatalog?TAG=${supplier}`} data-cy={`supplier-link-${supplier}`}>
                  {supplier}
                </S.SupplierLink>
              ))
            ) : (
              <p>No suppliers</p>
            )}
          </S.SupplierColumn>
          </>
        ))}
      </S.SupplierListContainer>
    </S.Container>
  );
};

export default SupplierListComponent;
