import ChooseLogin from "components/ChooseLogin/ChooseLogin";
import LeftMenuComponent from "components/LeftMenuComponent/LeftMenuComponent";
import ServiceSupport from "components/ServiceSupport/ServiceSupport";
import { componentsConfig as rest } from "srs.sharedcomponents/lib/esm/components/config";
import CustomHeader from "srs.sharedcomponents/lib/esm/components/organisms/Header/Layout2";
import CustomLanguageDropdown from "srs.sharedcomponents/lib/esm/components/organisms/LanguageDropdown/Layout2";
import WelcomeBuilder from "srs.sharedcomponents/lib/esm/components/organisms/Welcome/BuilderContent/WelcomeBuilder"
import BrowserSuppliers from "components/BrowserSuppliers/BrowserSuppliers";
import FooterBuilderComponent from "srs.sharedcomponents/lib/esm/components/organisms/Footer/FooterBuilder/FooterBuilder";
import BuilderUIComponent from "components/ServiceSupport/BuilderUIComponent";

const componentsConfig = {
  ...rest,
  Header: CustomHeader,
  LanguageDropdown: CustomLanguageDropdown,
  WelcomeContent: WelcomeBuilder,
  ChooseLogin: ChooseLogin,
  ServiceSupport: ServiceSupport,
  LeftMenuComponent: LeftMenuComponent,
  BrowserSuppliers: BrowserSuppliers,
  FooterContent: FooterBuilderComponent,
  BuilderUIComponent: BuilderUIComponent
};

export { componentsConfig };
