import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import BuilderModelComponent from 'srs.sharedcomponents/lib/esm/components/builder/srs.builderModel.components'
import { IBuilderModelParameter } from 'srs.sharedcomponents/lib/esm/models/builder/builderModelParameter.model'
import { getWebsiteName } from 'srs.sharedcomponents/lib/esm/utils/websiteName'

interface IBuilderUIComponent {
  modelKey: string;
  path: string;
  modelName: string;
}
const BuilderUIComponent: React.FC<IBuilderUIComponent> = ({
  modelKey,
  modelName,
  path,
}) => {
  const welcome = useAppSelector((state) => state.welcome.welcome)

  const getHomeParameters = () => {
    const websiteName = getWebsiteName()?.websiteName
    if (websiteName) {
        const homeParameters: IBuilderModelParameter = 
        { 
            websiteName: websiteName
        }
        return homeParameters;
    }
    else return {}
  }
  
if (!welcome ) return <></>
else return (
        <Container>
            <main id="main" role="main" className="pb-3">
                <Row>
                    <Col Col md={12}>
                        <Row>
                            <div className="col-12">
                                <BuilderModelComponent 
                                    modelKey='Ev-Servicing'
                                    path='/ev-servicing'
                                    modelName='content-page' 
                                    modelParameters={getHomeParameters()} 
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </main>
        </Container>
    )
}
export default BuilderUIComponent