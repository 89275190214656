import { globalCss } from "srs.sharedcomponents/lib/esm/styles";

export const globalStyles = globalCss({
  "*": {
    border: "none",
    padding: 0,
    margin: 0,
  },
  a: {
    color: "inherit",
  },
});
